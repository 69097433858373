import { FC } from "react";

import { Icon, IconProps } from "@chakra-ui/icons";

const CustomIcon: FC<Omit<IconProps, "css">> = ({ children, ...props }) => (
  <Icon width="24" height="24" viewBox="0 -960 960 960" fill="#e8eaed" {...props}>
    <path d="m363-390 117-71 117 71-31-133 104-90-137-11-53-126-53 126-137 11 104 90-31 133ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" />
  </Icon>
);

export default CustomIcon;
